<template>
  <div>
    <b-modal
      id="logout-modal"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <h5
        class="grey-text blue-text-hover float-right pl-1 pr-1"
        v-on:click="$bvModal.hide('logout-modal')"
      >
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </h5>
      <h2 class="mt-4 dark-blue-text text-center font-weight-bold">
        サインアウト
      </h2>
      <p class="grey-text text-center">サインアウトしてもよろしいですか？</p>
      <div class="row pl-3 pr-3 actions">
        <div class="col-12 col-md-6 col-lg-6 col-xl-6">
          <button
            class="btn btn-ds dark-blue white-text mb-3 pt-2 pb-2"
            type="submit"
            v-waves.light
            v-on:click="logout"
          >
            サインアウト
          </button>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-6">
          <button
            class="btn btn-ds light-grey white-text mb-3 pt-2 pb-2"
            type="button"
            v-waves.light
            v-on:click="$bvModal.hide('logout-modal')"
          >
            キャンセル
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['currentUser', 'currentRoute']),
  },

  methods: {
    logout() {
      let _this = this;

      _this.$bvModal.hide('logout-modal');

      _this.$store.state.modalLoaderMessage = 'サインアウト中';
      _this.$bvModal.show('modal-loader');

      this.$store.dispatch('setLogout');

      localStorage.removeItem('_accessToken');

      _this.googleLogout();

      setTimeout(() => {
        _this.$bvModal.hide('modal-loader');
        _this.$router.push({
          name: _this.currentRoute.includes('adminRoute')
            ? 'anonymousRoute-admin-login'
            : 'anonymousRoute-login',
        });
      }, 1000);
    },

    googleLogout() {
      let _this = this;

      _this.$gAuth
        .signOut()
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
