import axios from 'axios';
import store from '../../store';

const domainAPI = store.state.domainAPI;

// Get Notifications
const cus_notificationService_GetNotifications = (data) =>
  axios.get(domainAPI + '/api/notification', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
    params: {
      page:data.page
    }
  });

// Update Notifaction - Read Notification
const cus_notificationService_UpdateNotificationsToRead = (data) =>
  axios.post(
    domainAPI + '/api/notification/read_ids',
    {},
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      params: {
        ids:data.ids
      }
    }
  );

export {
  cus_notificationService_GetNotifications,
  cus_notificationService_UpdateNotificationsToRead,
};
